import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Disclosure } from '@headlessui/react'
import Countdown, { zeroPad } from 'react-countdown';

import { ChevronRightIcon, CheckIcon } from '@heroicons/react/solid'

import {
  SpeakerphoneIcon,
  RefreshIcon,
  GiftIcon,
  TrendingUpIcon,
  CashIcon,
  UserGroupIcon,
  LockClosedIcon,
  ChevronDownIcon
} from '@heroicons/react/outline'

import {
  Twitter,
  Reddit,
  Instagram,
  Telegram
} from '@icons-pack/react-simple-icons';

import Layout from "../components/layout"
import Seo from "../components/seo"

import Chart from "../images/chart.svg"

var dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const features = [
  {
    name: 'Token Buy-back',
    description: '5% of every transaction goes to our "MoonWhale" wallet. These funds are then used to periodically buy-back and burn MoonDash tokens to help keep the price of MoonDash in check (these funds are not accessible to the devs)',
    icon: RefreshIcon,
  },
  {
    name: 'Special Giveaways',
    description: 'We periodically run giveaways (usually every 1-2 weeks) in collaboration with other exciting tokens where you can win those tokens and special NFTs. To enter you only need to hold MoonDash - the more you hold, the more entries you will have.',
    icon: GiftIcon,
  },
  {
    name: 'Static Farming',
    description: 'Farm MoonDash tokens simply by holding. The more tokens you hold, the more you\'ll earn! No need to stake your tokens - earnings are sent to your wallet automatically! Sit back, relax and wach your wallet grow!',
    icon: CashIcon,
  },
  {
    name: 'Experienced Team',
    description: 'We\'re a friendly bunch of crypto nerds from all over the world. We have a long term vision for MoonDash - we\'re never gonna give you up and we\'re super excited to bring you lots of cool and new features!',
    icon: UserGroupIcon,
  }

]

const faqs = [
  {
    question: "What is MoonDash?",
    answer:
      "MoonDash is a mix and match ecosystem of giveaways, auto-generating liquidity, distribution and buy back function. All-in-one.",
  },
  {
    question: "How do I buy MoonDash?",
    answer:
      "Our token has launched on Pancakeswap, but we recommend that you buy using PooCoin Trade (since they offer automatic slippage). Simply follow one of the \"Buy MoonDash\" links on our website.",
  },
  {
    question: "How do I enter the giveaway?",
    answer:
      "If there's an active giveaway goin on, you'll be able to enter simply by going to the Giveaway page and and following instructions there.",
  },
  {
    question: "How do I know if I won the giveaway?",
    answer:
      "Winners will be annopunced on Telegram, Instagram & Twitter. You'll also be able to check the Giveaway page right here on our website to see a list of winners. Winnings will be sent out automatically.",
  },
  {
    question: "How do I get the distribution rewards?",
    answer:
      "You'll automatically receive MoonDash tokens when you hold. Sit back, relax and watch your wallet grow!",
  },
  {
    question: "How can I contact you?",
    answer:
      "You may contact us on Telegram or email us directly at contact@moondash.io",
  },
]


const roadmap = [
  [
    {
      content: 'Private sale',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
    {
      content: 'Initial website launch',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
    {
      content: 'Pre-sale',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
    {
      content: 'Initial marketing, including PooCoin Ads, etc',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
  ],
  [
    {
      content: 'Pancakeswap launch',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
    {
      content: 'CoinGecko Listing',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
    {
      content: '$5000 Airdrop giveaway',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
    {
      content: 'Giveaway improvements - collaborate with exciting tokens, instead of just giving out BNB',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
    {
      content: 'Bring on a marketing expert to help with marketing strategy',
      icon: CheckIcon,
      iconBackground: 'bg-green-500',
    },
    {
      content: 'CoinMarketCap Listing',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'Custom dex on the website',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: '“How to buy MoonDash” text & video guide',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'DessertSwap Audit + Dox',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'Live Token Stats on the Website',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'More ways to get giveaway entries & automated social sharing to enter',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'Automatic Giveaway winner notifications',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: '"Diamond Hands" entries - the longer you hold, the more giveaway entries you\'ll get',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'Animated live giveaway draw',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    
  
  ],

  [
    {
      content: 'Transparency Report',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'Certik Audit',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'MoonDash game release (name to be confirmed)',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'NFT Marketplace',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'MoonDash secret "sister" project 👀 (details to be announced soon)',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'Centralized Exchange Listings',
      icon: null,
      iconBackground: 'bg-gray-600',
    },
    {
      content: 'Many more exciting plans to be announced...',
      icon: null,
      iconBackground: 'bg-gray-600',
    }
  ],


]

const quarters = [
  {
    quarter: 'Q2 2021'
  },
  {
    quarter: 'Q3 2021'
  },
  {
    quarter: 'Q4 2021'
  }
]

let nowHourly = dayjs.utc();
nowHourly = nowHourly.add(1, 'hour').minute(0).second(0).format();

const hourlyRenderer = ({ minutes, seconds, completed }) => {
  // Render a countdown
  return <div className="rounded-xl bg-gray-900 flex py-6 lg:py-8 px-6 md:px-8 lg:px-10 items-start border border-gray-800">
    <StaticImage
      src="../images/clock.png"
      className="w-8 md:w-8 h-auto mr-4 md:mr-6 mt-2 flex-shrink-0"
      width={112}
      alt=""
      objectFit="contain"
    />
    <div className="space-y-1 flex flex-col items-start text-white">
      <h5 className="font-medium text-base md:text-lg leading-tight md:leading-tight text-left">Next Draw in..</h5>
      <div className="flex items-start space-x-2 pb-1">

        <div className="flex flex-col items-center text-center">
          <div className="text-3xl md:text-4xl font-medium">{zeroPad(minutes)}</div>
          <div className="uppercase tracking-widest text-xs md:text-sm text-gray-200">Mins</div>
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="text-3xl md:text-4xl font-normal">:</div>
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="text-3xl md:text-4xl font-medium">{zeroPad(seconds)}</div>
          <div className="uppercase tracking-widest text-xs md:text-sm text-gray-200">Secs</div>
        </div>
      </div>
    </div></div>;
};


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const IndexPage = () => {

  const [winnerLoaded, setWinnerLoaded] = useState(false)
  const [winner, setWinner] = useState([])
  // useEffect(() => {
  //   // get data from GitHub api
  //   fetch(`https://hourly-lottery-latest-winner-fetch.moondash.workers.dev/`)
  //     .then(response => {
  //       return response.json()
  //     }).then(json => {
  //       setWinnerLoaded(true)
  //       setWinner(json)
  //     })
  // }, [])
  return (
    <Layout>
      <Seo title="All-In-One BSC token" />
      <div className="container py-8">
        <div className="bg-red-600 text-white rounded p-6 text-sm">
          Attention! We're sorry to inform that the contract owner private keys have been compromised. Buy/sell at your own risk, as the person who compromised the keys may be able to pause trading, blacklist any addresses or reduce max transfer amount. Unfortunately due to this and other factors, this project is not being actively worked on for the forseeable future.
        </div>
      </div>
      <div className="text-white">
        <div className="container grid grid-cols-12 gap-8 sm:gap-12 md:gap-16 lg:gap-20 relative">
        <div className="circle absolute z-0 -top-48 md:-top-48 lg:-top-96 -right-48 lg:-right-64"></div>
          <div className="col-span-10 pt-6 md:pt-12 lg:pt-24 xl:pt-32 pb-4 md:pb-8 lg:pb-10 text-left relative z-30">
            <h1 className="text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-display font-bold mb-4 lg:mb-6 xl:mb-8 max-w-xs sm:max-w-sm md:max-w-4xl">
              All-In-One, Hyper Deflationary BSC Token</h1>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl xl:leading-relaxed mb-5 lg:mb-7 xl:mb-8 max-w-xs sm:max-w-sm md:max-w-3xl text-gray-200">MoonDash is a mix and match ecosystem of giveaways, auto-generating liquidity, distribution and buy back function. All-in-one.</p>



          </div>

          <div className="col-span-2 lg:py-8">
            <div className="relative">
              
              <StaticImage
                src="../images/rocket-2.png"
                className="absolute z-20 w-16 top-10 md:w-24 lg:w-32 -left-6 sm:-left-6 lg:-left-8 xl:-left-12 2xl:-left-20 2xl:w-36 xl:top-20 max-w-none"
                width={256}
                alt=""
                objectFit="contain"
              />

            </div>

          </div>

        </div>
        <div className="container relative z-30 grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-6">

          <div className="lg:col-span-2 grid grid-cols-1 gap-4 md:gap-6">
            <Link to="/giveaway" className="group h-auto sm:h-32 md:h-40 lg:h-44 rounded-xl bg-gradient-to-tr from-gray-800 to-gray-750 shadow-lg hover:shadow-xl relative overflow-hidden flex items-center">
              <div className="absolute z-10 w-full h-full bg-gray-750 opacity-0 group-hover:opacity-100 transition"></div>

              <div className="p-6 md:p-8 relative z-20">
                <h3 className="text-xl md:text-2xl xl:text-2-5xl 2xl:text-3xl font-medium tracking-tight">Enter the <StaticImage
                  src="../images/dogecoin.png"
                  className="inline w-6 md:w-7 lg:w-8 h-auto ml-1 align-middle"
                  width={64}
                  alt=""
                  objectFit="contain"
                /> <span style={{ color: "#D3B63C" }}>DOGE</span> Giveaway</h3>
                <div className="sm:flex sm:space-x-6 md:space-x-12">
                  <div className="mt-2 md:mt-3">
                    <div className="uppercase text-xs md:text-sm tracking-wider text-blue-400 font-medium">Entries open on</div>
                    <div className="font-medium text-lg md:text-xl lg:text-2xl">28th August</div>
                  </div>
                  <div className="mt-2 md:mt-3">
                    <div className="uppercase text-xs md:text-sm tracking-wider text-blue-400 font-medium">Winnings pot</div>
                    <div className="font-medium text-lg md:text-xl lg:text-2xl">7,500 DOGE</div>
                  </div>
                </div>
              </div>
              <StaticImage
                src="../images/hero-giveaway.png"
                className="absolute z-10 w-24 md:w-40 -bottom-4 md:-bottom-6 right-0 md:-right-6 lg:-right-12 xl:-right-6 2xl:right-2 max-w-none hero-img transform transition-transform group-hover:scale-105"
                width={480}
                alt=""
                objectFit="contain"
              />

            </Link>
            <Link to="/game" className="group h-auto sm:h-32  md:h-40 lg:h-44 rounded-xl bg-gradient-to-tr from-gray-800 to-gray-750 shadow-lg hover:shadow-xl relative overflow-hidden flex items-center">
              <div className="absolute z-10 w-full h-full bg-gray-750 opacity-0 group-hover:opacity-100 transition"></div>
              <div className="p-6 md:p-8 relative z-20">
                <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm bg-gray-700 text-white">
                  Launching Q4 2021
                </span>
                <h3 className="text-xl md:text-2xl xl:text-2-5xl 2xl:text-3xl font-medium mt-1 tracking-tight">Play the MoonDash Game</h3>
                <p className="text-base md:text-lg text-gray-300 mt-0.5 w-48 sm:w-full">Strap in and get your rocket ready for take-off!</p>
              </div>
              <StaticImage
                src="../images/hero-game.png"
                className="absolute z-10 w-32 md:w-56 xl:w-64 -bottom-4 md:bottom-2 right-0 md:-right-6 lg:-right-12 xl:-right-6 2xl:right-2 max-w-none hero-img transform transition-transform group-hover:scale-105"
                width={735}
                alt=""
                objectFit="contain"
              />
            </Link>

          </div>

          <div className="lg:col-span-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4 md:gap-6">
            <a href="https://poocoin.app/embed-swap?outputCurrency=0x2e44fad7F8C5351C759C68D721a01377E7bf9479" target="_blank" className="group h-24 md:h-32 lg:h-44 rounded-xl text-white bg-gradient-to-tr from-blue-500 to-blue-600 shadow-lg hover:shadow-xl relative overflow-hidden flex items-center">
              <div className="absolute z-10 w-full h-full bg-blue-500 opacity-0 group-hover:opacity-100 transition"></div>
              <div className="p-6 md:p-8 relative z-20">
                <h3 className="text-xl md:text-2xl xl:text-2-5xl 2xl:text-3xl font-medium tracking-tight">Buy MoonDash</h3>
                <p className="text-base md:text-lg text-blue-200 mt-0.5">PooCoin Trade</p>
              </div>
              <StaticImage
                src="../images/hero-buy.png"
                className="absolute z-10 w-24 md:w-40 -bottom-4 md:-bottom-6 right-0 md:-right-6 lg:-right-12 xl:-right-6 2xl:right-2 max-w-none hero-img transform transition-transform group-hover:scale-105"
                width={480}
                alt=""
                objectFit="contain"
              />
            </a>
            <a href="https://poocoin.app/tokens/0x2e44fad7f8c5351c759c68d721a01377e7bf9479" target="_blank" className="group h-24 md:h-32 lg:h-44 rounded-xl bg-gradient-to-tr from-gray-800 to-gray-750 shadow-lg hover:shadow-xl relative overflow-hidden flex items-center">
              <div className="absolute z-10 w-full h-full bg-gray-750 opacity-0 group-hover:opacity-100 transition"></div>
              <div className="p-6 md:p-8 relative z-20">
                <h3 className="text-xl md:text-2xl xl:text-2-5xl 2xl:text-3xl font-medium tracking-tight">View Chart</h3>
                <p className="text-base md:text-lg text-gray-300 mt-0.5">PooCoin Charts</p>
              </div>
              <StaticImage
                src="../images/hero-charts.png"
                className="absolute z-10 w-24 md:w-40 -bottom-4 md:-bottom-6 right-0 md:-right-6 lg:-right-12 xl:-right-6 2xl:right-2 max-w-none hero-img transform transition-transform group-hover:scale-105"
                width={480}
                alt=""
                objectFit="contain"
              />
            </a>
          </div>




        </div>
      </div>
      <div className="relative py-12 pt-20 sm:py-16 sm:pt-32 lg:py-24 lg:pt-48 z-20" id="features">
        <div className="container">
          <div className="text-center flex flex-col items-center">
            <h2 className="text-base lg:text-lg font-medium tracking-wider text-blue-500 uppercase py-3 px-6 lg:py-4 lg:px-8 bg-blue-500 bg-opacity-10 rounded-full inline-block">Features</h2>
            <p className="mt-3 lg:mt-5 text-3xl font-bold text-white tracking-tight sm:text-4xl lg:text-5xl xl:text-6xl">
              Why invest in MoonDash?
            </p>
            <p className="mt-4 max-w-prose text-lg md:text-xl lg:text-2xl text-gray-300">
              Diamond hands are rewarded, while participating for a chance to win the giveaway. Everything revolves around the token holders, for the holders!
            </p>
            <div className="flex flex-col items-center sm:flex-row sm:space-x-3 mt-4 lg:mt-5">
              <a href="/files/techrate.pdf" target="_blank" className="rounded-lg bg-gradient-to-tr from-gray-800 to-gray-750 shadow-md hover:shadow-lg group py-3 px-5 mt-3 relative overflow-hidden">
                <div className="absolute w-full h-full bg-gray-750 opacity-0 group-hover:opacity-100 transition left-0 top-0 z-10"></div>

                <div className="text-left text-white flex space-x-2.5 relative z-20">
                  <LockClosedIcon className="w-6 h-6 flex-shrink-0 text-green-500 relative z-20 mt-1.5" />
                  <div>
                    <span className="text-xs tracking-wider uppercase text-green-500">Completed</span>
                    <h5 className="font-medium text-base -mt-0.5">Techrate Audit (free)</h5>
                  </div>

                </div>
              </a>
              <div className="rounded-lg border-2 border-dashed border-gray-700 py-3 px-5 mt-3">

                <div className="text-left text-white flex space-x-2.5 relative z-20">
                  <LockClosedIcon className="w-6 h-6 flex-shrink-0 text-yellow-300 relative z-20 mt-1.5" />
                  <div>
                    <span className="text-xs tracking-wider uppercase text-yellow-300">Pending</span>
                    <h5 className="font-medium text-base -mt-0.5">Techrate Full Audit</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-12 grid grid-cols-1 gap-x-12 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-2 lg:gap-x-16 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name}>
                <div>
                  <span className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-500 bg-opacity-10">
                    <feature.icon className="h-6 w-6 text-blue-500" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-4">
                  <h3 className="text-lg md:text-xl lg:text-2xl font-medium text-white">{feature.name}</h3>
                  <p className="mt-2 text-base md:text-lg text-gray-300">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="relative py-12 sm:py-16 lg:py-24 z-20" id="tokenomics">
        <div className="container">
          <div className="text-center flex flex-col items-center">
            <h2 className="text-base lg:text-lg font-medium tracking-wider text-blue-500 uppercase py-3 px-6 lg:py-4 lg:px-8 bg-blue-500 bg-opacity-10 rounded-full inline-block">Tokenomics</h2>
            <p className="mt-3 lg:mt-5 text-3xl font-bold text-white tracking-tight sm:text-4xl lg:text-5xl xl:text-6xl">
              Distribution & Taxes
            </p>
            <p className="mt-4 max-w-prose text-lg md:text-xl lg:text-2xl text-gray-300 mb-10 md:mb-12 lg:mb-16">
              Our unique tokenomics allow us to have a good marketing budget to keep MoonDash going for many years to come, run our giveaways & fund any secret future MoonDash projects to benefit our holders.
            </p>
          </div>
          <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 items-center">
            <div className="relative">
              <div className="">
                <p className="text-base lg:text-lg font-medium tracking-wider text-blue-400 uppercase">Distribution</p>
                <div className="space-y-6 mt-4">
                  <div className="text-3xl text-white font-medium">
                    1,000,000,000,000
                    <p className="text-xs lg:text-sm tracking-wider text-gray-300 uppercase font-normal mt-1.5">Initial supply (1 Trillion)</p>
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-3 gap-6">
                    <div className="text-3xl text-white font-medium">
                      3%
                      <p className="text-xs lg:text-sm tracking-wider text-gray-300 uppercase font-normal mt-1.5">Private sale</p>
                    </div>
                    <div className="text-3xl text-white font-medium">
                      20.5%
                      <p className="text-xs lg:text-sm tracking-wider text-gray-300 uppercase font-normal mt-1.5">Presale</p>
                    </div>
                    <div className="text-3xl text-white font-medium">
                      14.5%
                      <p className="text-xs lg:text-sm tracking-wider text-gray-300 uppercase font-normal mt-1.5">Liquidity</p>
                    </div>
                    <div className="text-3xl text-white font-medium">
                      57%
                      <p className="text-xs lg:text-sm tracking-wider text-gray-300 uppercase font-normal mt-1.5">Burned</p>
                    </div>
                    <div className="text-3xl text-white font-medium">
                      5%
                      <p className="text-xs lg:text-sm tracking-wider text-gray-300 uppercase font-normal mt-1.5">Team & Marketing</p>
                    </div>

                  </div>
                </div>
              </div>
              <div className="mt-10 pt-10 border-t border-gray-700">
                <p className="text-base lg:text-lg font-medium tracking-wider text-blue-400 uppercase">Taxes</p>
                <div className="space-y-6 mt-4">
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-6">
                    <div className="text-3xl text-white font-medium">
                      2%
                      <p className="text-xs lg:text-sm tracking-wider text-gray-300 uppercase font-normal mt-1.5">MoonDash Redistribution</p>
                    </div>
                    <div className="text-3xl text-white font-medium">
                      5%
                      <p className="text-xs lg:text-sm tracking-wider text-gray-300 uppercase font-normal mt-1.5">"MoonWhale" Buy-back & burn</p>
                    </div>
                    <div className="text-3xl text-white font-medium">
                      5%
                      <p className="text-xs lg:text-sm tracking-wider text-gray-300 uppercase font-normal mt-1.5">Project wallet (Giveaways, Devs, Marketing & Special projects)</p>
                    </div>

                  </div>
                </div>
              </div>


            </div>

            <div className="mt-10 lg:-mx-4 relative lg:mt-0 px-8 lg:px-0 pt-8 lg:pt-0 border-t lg:border-t-0 border-gray-700" aria-hidden="true">
              <img
                className="relative mx-auto"
                width={490}
                src={Chart}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative py-12 sm:py-16 lg:py-24 bg-gray-900 z-20" id="roadmap">
        <div className="container">
          <div className="space-y-12 md:space-y-16">
            <div className="text-center flex flex-col items-center">
              <h2 className="text-base lg:text-lg font-medium tracking-wider text-blue-500 uppercase py-3 px-6 lg:py-4 lg:px-8 bg-blue-500 bg-opacity-10 rounded-full inline-block">Roadmap</h2>
              <p className="mt-3 lg:mt-5 text-3xl font-bold text-white tracking-tight sm:text-4xl lg:text-5xl xl:text-6xl">
                See Our Progress
              </p>
              <p className="mt-4 max-w-prose text-lg md:text-xl lg:text-2xl text-gray-300">
                We have many great ideas that we're excited to bring to the holders of MoonDash. Got any ideas of your own? We love community input - let us know on Telegram!
              </p>
            </div>
            <ul className="mx-auto space-y-6 sm:grid md:grid-cols-2 sm:gap-8 sm:space-y-0 xl:grid-cols-3 text-white">
              {roadmap.map((quarter, index) => (
                <li className="rounded-lg bg-gray-800 p-8 sm:p-12 lg:p-14" key={index}>
                  <h3 className="text-xl lg:text-2xl font-medium mb-8">{quarters[index].quarter}</h3>
                  <div className="flow-root">
                    <div className="-mb-8">
                      {quarter.map((item, index) => (
                        <div key={index}>
                          <div className="relative pb-8">
                            {index !== quarter.length - 1 ? (
                              <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-600" aria-hidden="true" />
                            ) : null}
                            <div className="relative flex space-x-3">
                              <div>
                                <span
                                  className={classNames(
                                    item.iconBackground,
                                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-gray-800'
                                  )}
                                >
                                  {item.icon !== null ? (
                                    <item.icon className="h-5 w-5 text-white" aria-hidden="true" />
                                  ) : null}

                                </span>
                              </div>
                              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p className="text-sm lg:text-base text-gray-100">
                                    {item.content}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                </li>
              ))}


            </ul>
          </div>
        </div>
      </div>
      <div className="relative py-12 sm:py-16 lg:py-24 z-20" id="team">
        <div className="container">
          <div className="space-y-12 md:space-y-16">
          <div className="text-center flex flex-col items-center">
              <h2 className="text-base lg:text-lg font-medium tracking-wider text-blue-500 uppercase py-3 px-6 lg:py-4 lg:px-8 bg-blue-500 bg-opacity-10 rounded-full inline-block">Team</h2>
              <p className="mt-3 lg:mt-5 text-3xl font-bold text-white tracking-tight sm:text-4xl lg:text-5xl xl:text-6xl">
              Meet Our Core Team
              </p>
              <p className="mt-4 max-w-prose text-lg md:text-xl lg:text-2xl text-gray-300">
              We're a passionate group of crypto-enthusiasts, dedicated to bringing the best token to your wallet
              </p>
            </div>
            <ul className="mx-auto space-y-12 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 xl:grid-cols-3 text-center text-white">
              <li>
                <div className="space-y-6">
                  <StaticImage
                    src="../images/speronic.png"
                    className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                    width={360}
                    alt=""
                    objectFit="contain"
                  />
                  <div className="space-y-2">
                    <div className="leading-6 space-y-1">
                      <h3 className="text-2xl md:text-3xl font-medium">Speronic</h3>
                      <p className="text-blue-400 text0base md:text-lg">Web Design & Development</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="space-y-6">
                  <StaticImage
                    src="../images/lamb-o.png"
                    className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                    width={360}
                    alt=""
                    objectFit="contain"
                  />
                  <div className="space-y-2">
                    <div className="leading-6 space-y-1">
                      <h3 className="text-2xl md:text-3xl font-medium">Lamb O</h3>
                      <p className="text-blue-400 text0base md:text-lg">Marketing Lead</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="space-y-6">
                  <StaticImage
                    src="../images/chandler.png"
                    className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                    width={360}
                    alt=""
                    objectFit="contain"
                  />
                  <div className="space-y-2">
                    <div className="leading-6 space-y-1">
                      <h3 className="text-2xl md:text-3xl font-medium">Chandler</h3>
                      <p className="text-blue-400 text0base md:text-lg">Social & Community Management</p>
                    </div>
                  </div>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>

      <div className="relative pt-12 sm:pt-16 lg:pt-24 bg-gray-900 z-20" id="faqs">
        <div className="container max-w-4xl">
          <div className="">
          <div className="text-center flex flex-col items-center">
              <h2 className="text-base lg:text-lg font-medium tracking-wider text-blue-500 uppercase py-3 px-6 lg:py-4 lg:px-8 bg-blue-500 bg-opacity-10 rounded-full inline-block">FAQs</h2>
              <p className="mt-3 lg:mt-5 text-3xl font-bold text-white tracking-tight sm:text-4xl lg:text-5xl xl:text-6xl">
              Got any questions?
              </p>
              <p className="mt-4 max-w-prose text-lg md:text-xl lg:text-2xl text-gray-300">
              Find answers to the most commonly asked questions here, or give us a shout on Telegram - one of our team members or someone from our friendly community will be happy to help!
              </p>
            </div>
            <dl className="mt-8 space-y-6 divide-y divide-gray-700">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-100">
                          <span className="font-medium text-white text-lg md:text-xl">{faq.question}</span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base md:text-lg text-gray-200">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

